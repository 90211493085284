<template>
	<div :id="hashtag" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-body pt-4">
					<div class="form-group">
						<input class="form-control" type="text" placeholder="Заголовок" v-model="form.title" @keyup.enter="submitForm()" />
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-light" data-dismiss="modal">Закрыть</button>

					<button v-if="!loading" class="btn btn-primary" type="submit" @click.prevent="submitForm()">
						Подтвердить
					</button>
					<button v-else class="btn btn-primary" type="button" disabled>
						<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
						Загрузка...
					</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
	<!-- /.modal -->
</template>

<script>
import log from '@/plugins/log';

export default {
	name: 'AddProjectModal',
	data: () => {
		return {
            hashtag: 'add-project-modal',
			_form: {
				title: '',
			},
			form: {},
			loading: false,
		};
	},
	mounted() {
		this.resetForm();
	},
	methods: {
        openModal() {
            $(`#${this.hashtag}`).modal('show');
        },
        closeModal() {
			$(`#${this.hashtag}`).modal('hide');
		},
		resetForm() {
			this.form = Object.assign({}, this._form);
		},
		async submitForm() {
			if (this.loading) return $.NotificationApp.send('Error', 'Please wait', 'top-right', '', 'error');

			if (!this.form.title)
				return $.NotificationApp.send('Error', 'Please enter the title', 'top-right', '', 'error');

            this.loading = true;

            var [err, response] = await this.$orm.mutate({
                project: {
                    insert: {
                        objects: {
                            title: this.form.title
                        }
                    }
                }
            })

			if (err) {
				this.loading = false;
				log.error('code-modal', 'methods.submitForm', '$http.request', err);
				return $.NotificationApp.send('Error', err.message, 'top-right', '', 'error');
			}
			if (response.error) {
				this.loading = false;
				log.error('code-modal', 'methods.submitForm', '$http.response', response);
				return $.NotificationApp.send('Error', response, 'top-right', '', 'error');
			}
			this.loading = false;

			this.resetForm();
            this.closeModal();
		},
	},
};
</script>

<style scoped></style>
