<template>
    <div class="card d-block">
        <div class="card-header">
            <p-parts-widget :project="project" />

            <h4 class="mt-0 mb-0 card-title">
                <router-link :to="{ name: 'ProjectView', params: { id: project.id } }">
                    <p-parts-color-badge :project="project" />
                    <span class="text-title">{{ project.title }}</span>
                </router-link>
            </h4>
        </div>
        <div class="card-body pb-3">
            <div v-if="project.is_finished" class="badge badge-secondary mb-3">Завершен</div>

            <div class="mb-3" v-if="project.users_categories.length">
                <p-parts-category :project="project" />
            </div>

            <div class="mb-2 description" v-if="project.description" v-html="project.description"></div>

            <p-parts-stats :project="project" />

            <p-parts-members :project="project" />
        </div>

        <project-progress :project="project" />
    </div>

</template>

<script>
import ProjectProgress from '@/components/progress/ProjectProgress.vue'

import PPartsWidget from '@/components/project/_parts/Widget.vue'
import PPartsMembers from '@/components/project/_parts/Members.vue'
import PPartsStats from '@/components/project/_parts/Stats.vue'
import PPartsCategory from '@/components/project/_parts/Category.vue'
import PPartsColorBadge from '@/components/project/_parts/ColorBadge.vue'

export default {
    name: 'ProjectCard',
    props: ['project'],
    components: {
        ProjectProgress,
        PPartsMembers,
        PPartsWidget,
        PPartsStats,
        PPartsCategory,
        PPartsColorBadge
    }
}
</script>

<style scoped>
.card-title {
    line-height: 26px;
}
</style>