<template>
    <div class="card bg-light d-block">
        <div class="card-header bg-light">
            <p-parts-finished-widget :project="project" />
        </div>
        <div class="card-body pb-3 pt-0">
            <h4 class="mt-0 mb-1 card-title">
                <router-link :to="{ name: 'ProjectView', params: { id: project.id } }">
                    <span class="text-title">{{ project.title }}</span>
                </router-link>
            </h4>
            <div v-if="project.is_finished" class="badge badge-secondary mb-2">Завершен</div>

            <div class="mb-1 description" v-if="project.description" v-html="project.description"></div>

            <p-parts-stats :project="project" />
            <p-parts-members :project="project" />
        </div>

        <project-progress :project="project" />
    </div>

</template>

<script>
import ProjectProgress from '@/components/progress/ProjectProgress.vue'

import PPartsFinishedWidget from '@/components/project/_parts/FinishedWidget.vue'

import PPartsMembers from '@/components/project/_parts/Members.vue'
import PPartsStats from '@/components/project/_parts/Stats.vue'

export default {
    name: 'ProjectCard',
    props: ['project'],
    components: {
        ProjectProgress,
        PPartsMembers,
        PPartsFinishedWidget,
        PPartsStats
    }
}
</script>

<style scoped>
.card-title {
    line-height: 26px;
}
</style>