<template>
    <p v-if="show">
        <b>{{ invite.inviter }}</b> пригласил в проект <b>{{ invite.project }}</b>
	
        <button v-if="!loading" type="button" class="btn btn-sm btn-outline-success btn-rounded ml-2 mr-1" @click="accept()">Принять</button>
        <button v-if="!loading" type="button" class="btn btn-sm btn-outline-danger btn-rounded" @click="decline()">Отклонить</button>

        <button v-if="loading" class="btn btn-sm btn-outline-secondary btn-rounded ml-2" type="button" disabled>
            <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
            Загрузка...
        </button>
    </p>
</template>

<script>
export default {
    name: 'ProjectInviteRow',
    props: ['invite'],
    data: () => {
        return {
            loading: false,
            show: true
        }
    },
    methods: {
        async accept() {
            this.loading = true

            var [err] = await this.$orm.mutate({
                project_guest: {
                    update: {
                        where: { id: { _eq: this.invite.id } },
                        _set: { is_confirmed: true }
                    }
                }
            })
            if(err) console.log('ProjectInviteRow.accept.err', err)

            setTimeout(() => { this.loading = false; this.show = false; }, 500);
        },
        async decline() {
            this.loading = true

            var [err] = await this.$orm.mutate({
                project_guest: {
                    delete: {
                        where: { id: { _eq: this.invite.id } }
                    }
                }
            })
            if(err) console.log('ProjectInviteRow.decline.err', err)

            setTimeout(() => { this.loading = false; this.show = false; }, 500);
        }
    }
}
</script>

<style>

</style>