<template>
    <div class="row">
        <div class="col-6">
            <div v-if="project.finished_at" class="finish-date">{{ format_date(project.finished_at) }}</div>
        </div>

        <div class="col-6 text-right">
            <disabled-timer-badge :object="{ project_id: project.id }" :timers="project.timers"/>

            <a title="Добавить в избранное" @click.prevent="toggleFavorite()">
                <i v-if="!loading.favorite" class="mdi mr-1" :class="{ 'mdi mdi-star-outline': !isFavorited, 'mdi-star': isFavorited }"></i>
                <i v-else class="spinner-border spinner-border-sm mr-1"></i>
            </a>

            <a v-if="project_description" title="Редактировать описание" @click.prevent="project_description.editValue()">
                <i class="mdi mdi-clipboard-text-outline mr-1"></i>
            </a>

            <a title="Удалить проект" v-if="!project.is_deleted"  @click.prevent="deleteItem()">
                <i v-if="!loading.delete" class=" mdi mdi-delete"></i>
                <i v-else class="spinner-border spinner-border-sm"></i>
            </a>
        </div>
    </div>
</template>

<script>
import dayjs from '@/plugins/day'
import DisabledTimerBadge from '@/components/timer/DisabledTimerBadge.vue' 

export default {
    name: 'ProjectCardWidget',
    props: ['project', 'project_description'],
    data: () => {
        return {
            loading: {
                delete: false,
                favorite: false
            }
        }
    },
    computed: {
        isFavorited() {
            return this.project.favorited_by && this.project.favorited_by.length
        }
    },
    methods: {
        format_date(date) {
			return dayjs(date).format('DD.MM HH:mm');
		},
        async deleteItem() {
            if(!confirm(`Удалить ${this.project.title} ?`))
                return false

            this.loading.delete = true
            var [err, response] = await this.$orm.mutate({
                project: {
                    update: {
                        where: { id: { _eq: this.project.id }},
                        _set: { is_deleted: true }
                    }
                }
            })
            if(!err && !response.error)
                this.project.is_deleted = true
            
            this.loading.delete = false
        },
        async toggleFavorite() {
            this.loading.favorite = true

            if(this.isFavorited) {
                var [err, response] = await this.$orm.mutate({
                    project_favorite: {
                        delete: {
                            where: { id: { _eq: this.project.favorited_by[0].id } }
                        }
                    }
                }, { getFirst: true })
                if(!err && !response.error)
                    this.project.favorited_by = null
            } else {
                var [err, response] = await this.$orm.mutate({
                    project_favorite: {
                        insert: {
                            objects: {
                                project_id: this.project.id
                            }
                        }
                    }
                }, { getFirst: true })
                if(!err && !response.error)
                    this.project.favorited_by = [response]
            }


            this.loading.favorite = false
        }
    },
    components: {
        DisabledTimerBadge
    }
}
</script>

<style scoped>
div {
    font-size: 16px !important;
}
.greenish-badge:hover {
    color: #10c469;
    border-color: #10c469;
    transition: all 0.4s ease;
}
.redish-badge:hover {
    color: #ff5b5b;
    border-color: #ff5b5b;
    transition: all 0.4s ease;
}
.finish-date {
    line-height: 24px;
    font-size: 12px !important;
}
</style>