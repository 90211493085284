<template>
    <div>
        <i class="mdi mdi-flag-variant mr-1"></i>
        <b>{{ category.category.title }}</b>
    </div>
</template>

<script>
export default {
    name: 'ProjectCategory',
    props: ['project'],
    computed: {
        category() {
            return this.project.users_categories.length ? this.project.users_categories[0] : null
        }
    }
}
</script>